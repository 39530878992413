@import "~bootstrap/scss/_tables";

.cms-content {
  ul {
    list-style-type: disc;
    padding-left: 30px;
  }
  table {
    width: 100%;
    @extend .table-responsive
  }
  th {
    padding: 0.4rem 1rem 0.4rem 0.6rem;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 500;
    background-color: #cccccc;
    text-transform: uppercase;
  }
  td {
    padding: 0.4rem 1rem 0.4rem 0.6rem;
  }
}

.cms-page-view {
  .page-main {
    @extend .cms-content
  }
}
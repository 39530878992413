@mixin create-notification($min-height: 3.5rem, $ellipsis: false) {
  .notification {
      background-color: white;
      min-height: 1.5rem;
      z-index: 99;
      position: sticky;
      top: 0;

      .spacer {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          padding: 0!important;
          background-color: black;
          min-height: unset;
      }
      div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: white;
        color: black;
        min-height: 1.5rem;
        vertical-align: middle;
        font-size: small;
        cursor: default;
        padding: 0 1rem;

        @if($ellipsis) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .system {
        background-color: #cc0000;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 1.5rem;
        border-bottom: 1px solid black;

          div {
              background-color: unset;
          }
      }
      .shop {
          overflow: hidden;
          border-bottom: 1px solid black;
          .link {
              text-decoration: underline;
          }
          .countdown {
              padding: 0;
              width: 238px;
          }
      }
      .moving-ticker {
          width: max-content;
          display: block;
          min-height: unset;
          height: 100%;
          left: 0;
          position: absolute;
          display: flex;
          white-space: nowrap;
          padding: 0 !important;
          animation: ticker-kf 36s linear infinite;
      }
      .moving-ticker:first-of-type {
          z-index: 1;
      }
      .static-ticker {
          div {
              display: block;
          }
      }
  }

  .dark {
      div, a {
          background-color: black;
          color: white;
      }
      .spacer {
          background-color: white;
      }
  }

  @keyframes ticker-kf {
      0% {
          transform: translate3d(0, 0, 0);
          opacity: 1;
      }

      100% {
          transform: translate3d(-50%, 0, 0);
          opacity: 1;
      }
  }
  @-moz-keyframes ticker-kf {
      0% {
          transform: translate3d(0, 0, 0);
          opacity: 1;
      }

      100% {
          transform: translate3d(-50%, 0, 0);
          opacity: 1;
      }
  }
  @-webkit-keyframes ticker-kf {
      0% {
          transform: translate3d(0, 0, 0);
          opacity: 1;
      }

      100% {
          transform: translate3d(-50%, 0, 0);
          opacity: 1;
      }
  }
  @-o-keyframes ticker-kf {
      0% {
          transform: translate3d(0, 0, 0);
          opacity: 1;
      }

      100% {
          transform: translate3d(-50%, 0, 0);
          opacity: 1;
      }
    }
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the germanlaw component.
// -----------------------------------------------------------------------------

.price:after{
  //content: '*';
}

.tax-footer {
  text-align: center;
}

.tax-asterisk {
  margin-left: -2px;
}

.price-box {
  &.price-final_price {
    display: inline-block;
  }
}
// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
@import "~bootstrap/scss/mixins/_breakpoints";

$footer-border-color: rgba(0, 0, 0, 0.1);
$footer-link-color: grey;

footer {
  font-size: 0.75rem;
  flex: 0 1 auto;
  width: 100%;
  height: auto;
  margin-top: 4rem;
  text-transform: uppercase;
  hr {
    border-color: $footer-border-color;
    margin: 0;
  }

  .footer-links {
    margin: 1rem 0 1rem 0;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    a {
      color: $footer-link-color;
      padding: 0 0.5rem 0.2rem 0.5rem;
      text-transform: uppercase;
    }
  }

  .social-links {
    display: flex;
    justify-content: center;
    img {
      height: 35px;
      width: 35px;
      margin: 1rem 0.6rem
    }
  }
  .copyright {
    margin: 1rem 0 1rem 0;
    p {
      text-align: center;
      color: $footer-link-color;
    }
  }
}
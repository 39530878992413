img:focus,
a:focus,
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn:focus,
.btn.active {
  outline: 0 !important;
  box-shadow: none !important;
}

ol, ul, dl {
  margin: 0;
}

$base-size: 1.0rem;
$item-padding: 0.75rem;
$item-size: $base-size * 2.0;
$logo-height: $base-size * 6;
$logo-height-lg: $base-size * 8;
$logo-height-sm: $item-size + $item-padding;
$logo-height-md: $base-size * 5;
$header-top-margin: $item-size + (2 * $item-padding);

// Margins
$margin-small: 0.25rem;
$margin-medium: 0.5rem;
$margin-default: 1rem;

// Paddings
$padding-small: 0.25rem;
$padding-medium: 0.5rem;
$padding-default: 1rem;

$spacing-medium: 0.5rem;
$dropdown-background: #f0f0f0;

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@import "variables";
@import "~bootstrap/scss/mixins/hover";
@import "~bootstrap/scss/_buttons";

@mixin btn-part($color,$bg-color) {
  color: $color;
  background-color: $bg-color;
}

@mixin create-button(
  $bg,
  $color,
  $active-bg,
  $active-color,
  $disabled-color,
  $disabled-background,
  $border: none
) {
  border: $border;
  color: $color;
  background-color: $bg;
  padding: 0 0.75rem;
  line-height: 2.25;
  white-space: nowrap;
  &:hover {
    color: $active-color;
    background-color: $active-bg;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    color: $disabled-color;
    background-color: $disabled-background;
  }
  &.active,
  &:active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background: $active-bg none;
  }
}

.btn-group > .btn {
  flex-grow: 0;
}

.btn {
  @include hover {
    cursor: pointer;
  }
}

a {
  &.btn {
    padding: 0 0.8rem;
    flex-grow: 0;
  }
}

// Generate styles
.btn-default {
  @include create-button(
                  $btn-default-bg,
                  $btn-default-color,
                  $btn-default-bg-hover,
                  $btn-default-color-hover,
                  $btn-disabled-color,
                  $btn-disabled-bg
  );
}

.btn-primary {
  @include create-button(
                  $btn-primary-bg,
                  $btn-primary-color,
                  $btn-primary-bg-hover,
                  $btn-primary-color-hover,
                  $btn-disabled-color,
                  $btn-disabled-bg,
                  $btn-primary-border
  );

  &.xpress {
    width: 60%;
    line-height: 1.7;
    white-space: normal;

    &:hover {
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.btn-danger {
  @include create-button(
                  $btn-danger-bg,
                  $btn-danger-color,
                  $btn-danger-bg-hover,
                  $btn-danger-color-hover,
                  $btn-disabled-color,
                  $btn-disabled-bg
  );
}

button {
  &.action {
    @extend .btn;
  }

  &.primary {
    @extend .btn-primary;
  }
}


@import "~bootstrap/scss/mixins/breakpoints";

$alert-info-color: #31708f;
$alert-info-border-color: #bcdff1;
$alert-info-background-color: #d9edf7;

.page {
  &.messages {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 20000;
    margin: 1rem;
  }
}

.fixed-message {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  &.message-cookie {
    color: $alert-info-color;
    background-color: $alert-info-background-color;
    border-top: 1px solid $alert-info-border-color;
    padding: 0.5rem 1rem;
    .cookie-row {
      display: flex;
      flex-wrap: wrap;
      .cookie-message, .cookie-button {
        flex: 1 1 auto;
      }
      .cookie-message {
      }
      .cookie-button {
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        .btn {
          width: 100%;
        }
        .btn-left {
          margin-bottom: 0.5rem;
        }
        .btn-right {
          margin-top: 0;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      .cookie-row {
        display: flex;
        .cookie-message, .cookie-button {
          margin-top: auto;
          margin-bottom: auto;
        }
        .cookie-message {
          padding-right: 1rem;
        }
        .cookie-button {
          button {
            min-width: 4rem;
          }
          margin-left: auto;
          flex: 1 1 auto;
          flex-wrap: nowrap;
          margin-bottom: 0.4rem;
          margin-top: 0.5rem;
          .btn-left {
            margin-right: 0.5rem;
            margin-bottom: 0;
          }
          .btn-right {
            margin-left: 0.5rem;
          }
          .btn {
          }
        }
      }
    }
    @include media-breakpoint-up(md) {
      .cookie-row {
        display: flex;
        flex-wrap: nowrap;
        .cookie-message, .cookie-button {
          margin-top: auto;
          margin-bottom: auto;
        }
        .cookie-message {
          padding-right: 1rem;
        }
        .cookie-button {
          margin-left: auto;
          flex: 0 1 auto;
          margin-bottom: auto;
          margin-top: auto;
          .btn {
          }
        }
      }
    }
  }
}

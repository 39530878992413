@import "../base/typography";
@import "../layout/main";
@import '~bootstrap/scss/mixins/breakpoints';
@import "~bootstrap/scss/_variables";
@import "../base/helpers";



@mixin create-basket-content($border-color) {
  .basket-content {
    @include create-flex-complete($direction: column);
    height: 100%;
    white-space: nowrap;
    z-index: 99;

    .basket-header {
      @include create-flex-complete($justify: flex-start);
      padding: $padding-default;
      border-bottom: 1px solid $border-color;

      .continue-shopping {
        button {
          span {
            &:after {
              content: '>';
              margin-left: 5px;
            }
          }
        }
      }
    }

    .basket-items {
      overflow-x: hidden;

      .product-item {
        @include create-flex-complete();
        padding: $padding-default;
        border-bottom: 1px solid $border-color;

        > div {
          &.product-item-image {
            padding: 0;
            height: 4.2rem;

            img {
              height: 100%;
            }
          }

          &.product-item-information {
            padding: 0 $padding-default;
            overflow: hidden;
            flex: 1 1 auto;

            > div {
              @include ellipsis;
            }
          }
        }

        .remove-btn {
          align-self: flex-start;
        }
      }
    }

    .basket-message {
      margin-top: $margin-default;
      text-align: center;
    }

    .basket-footer {
      .summary {
        padding: $padding-default;

        .subtotal {
          @include create-flex-complete($justify: space-between);
          padding: 0.2rem 0 0.2rem 0;
          font-size: 1.1rem;

          .label, .amount {
            flex: 0 1 auto;
          }

          .label {
            &:after {
              content: ':';
            }
          }
        }
      }

      .actions {
        @include create-flex-complete($wrap: wrap);
        padding: $padding-default;
      }
    }
  }
}

@mixin create-cart-items($information-col-size,$item-spacing,$border-color) {
  .cart-items {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .row-cart-item {
      display: flex;
      flex-wrap: nowrap;
      padding: $item-spacing 0;
      border-bottom: 1px solid $border-color;

      > div {
        margin: auto 0;

        &.item-main {
          @include create-flex(1, 1, auto);

         a {
           float: none;
         }
        }

        &.row-item-information {
          @if ($information-col-size > 0) {
            @include create-col($information-col-size);
          }
        }
      }
    }

    img {
      max-width: 100%;
    }
  }
}

.checkout-cart-index {
  @include create-cart-items(3, 0.5rem, #ccc);

  .page-main {
    .btn-group {
      flex-wrap: wrap;

      .btn {
        margin: $margin-small $margin-default $margin-small 0;
      }
    }
  }

  .form-control {
    &.qty {
      width: 30px;
    }
  }

  .form-group {
    &.form-group-inline {
      margin: 0;

      .form-control, label {
        display: inline-block;
        padding: 0;
        margin: 0;
      }
    }
  }

  .section {
    margin-bottom: $margin-medium;
  }
}

.overlay-blur {

  &.basket-sidenav-open {
    @include create-overlay(5px,  rgba(199, 201, 199, 0.4));
  }

  &.basket-sidenav-closed {
    background-color: unset;
  }
}

.basket {
  position: relative;

  .basket-icon {
    cursor: pointer;
  }

  .basket-drop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 375px;
    background-color: #ffffff;
    border-left: 1px solid #CCCCCC;
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
    box-shadow: -8px 0px 10px -1px rgba(199, 201, 199, 0.4);
    z-index: 99;

    @include create-basket-content(#ccc);
    .loading-mask {
      img {
        width: 30px;
      }
    }
  }

  .basket-closed {
    transform: translateX(100%);
    overflow-x: hidden;
    box-shadow: none;
  }

  @include media-breakpoint-down(sm) {
    .basket-drop {
      min-width: 100%;
    }
  }
}

.mini-cart {
  .btn-group {
    a.btn:last-child {
      margin-right: 0;
    }

    justify-content: flex-end;
  }
}

.checkout-cart-index {
  .checkout-methods-items {
    li {
      > div {
        display: inline;
        margin-left: $margin-default;
      }
    }

    li {
      .paypal {
        display: none
      }
    }
  }

  .page-main {
    ul {
      margin-top: $margin-default;
      text-align: right;

      li {
        display: inline;
      }
    }

    .section-content {
      .form-group {
        input {
          font-size: 1.1rem;
          width: 3rem;
        }
      }

      font-size: 1.1rem;
    }

    .cart-totals {
      border-bottom: 1px solid #ccc;
      margin-top: 0.9rem;

      .table-wrapper {
        text-align: right;

        div.totals {
          display: inline;
          font-size: 1.2rem;
          padding-left: $padding-default;
        }

        div.totals.totals-description:after {
          content: ':';
        }
      }
    }

    .btn-group {
      width: 100%;
      justify-content: flex-end;

      .btn {
        margin: $margin-default 0 0.5rem $margin-default;
      }
    }
  }
}

.cart-empty {
  a:after {
    content: '>';
  }
}

.alert {
  button {
    &.close {
      font-size: 1.8rem;
      line-height: 1.3rem;
    }
  }
}


// Default display products

@mixin products-display() {
  .product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    font-weight: 300;
    color: #262626;

    .content {
      a {
        width: 100%;
        height: 100%;
      }
    }

    .product-description {
      margin-bottom: 0.5rem !important;
      display: flex;
      flex-direction: column;
      height: 100%;

      .product-price {
        margin-top: -0.6rem;
      }

      .product-direct-add-to-cart {
        margin-top: 0.5rem;
      }

      a, a:hover p {
        color: #454040;
        text-decoration: none;
      }

      a:hover {
        font-weight: normal;
      }
    }
  }
}

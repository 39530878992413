/* Overrides for slick.js library */

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.slick-next,
.slick-prev {
  border: 1px solid #C7C9C7 !important;
  border-radius: 50%  !important;
  padding: 1rem  !important;
  transition: background-color .3s ease  !important;
  z-index: 1 !important;

  &:hover {
    background-color: #c7c9c7;
    transition: background-color .3s ease  !important;
  }
}

.slick-prev:before,
.slick-next:before
{
  position: absolute  !important;
  top: 4px  !important;
  left: 0  !important;
  right: 0  !important;
  font-family: MetaPro-Normal, serif !important;
  color: #212529 !important;
}

$btn-primary-color: #54585A;
$btn-primary-color-hover: #FFFFFF;
$btn-primary-bg: #FFFFFF;
$btn-primary-bg-hover: #54585A;
$btn-primary-border: 1px solid #54585A;

$btn-default-color: #252525;
$btn-default-color-hover: #252525;
$btn-default-bg: #979696;
$btn-default-bg-hover: #808080;

$btn-danger-color: #F04C1E;
$btn-danger-color-hover: #353434;
$btn-danger-bg: #353434;
$btn-danger-bg-hover: #F04C1E;

$btn-disabled-color: #969595;
$btn-disabled-bg: #EEEFEF;

$element-color:#969595;

@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/_variables';

[responsive-background-image] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  background-color: whitesmoke;

  img {
    max-width: 100%;
    width: 100%;
    display: none;
  }
  .content {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
  }
  &.image-transparent-hover {
    .content {
      z-index: 1;
    }
    &:after, &:before {
      position: absolute;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
    &:after {
      content: '\A';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.6);
    }
    &:hover {
      &:after, &:before {
        opacity: 0;
      }
    }
  }
}

$aspect-ratios: (
        "2-1": 1/2,
        "1-1" : 1/1,
        "5-7": 7/5,
        "7-5":5/7,
        "1-2" : 2/1,
);

@function get-ratio($ratio) {
  @return map_get($aspect-ratios, $ratio);
}

@function get-padding-by-ratio($ratio){
  @return get-ratio($ratio) * 100%;
}


@mixin createImagePadding($breakpoint,$width:0) {
  @each $name, $value in $aspect-ratios {
    $variableName: pa-#{$name};
    @if $width > 0 {
      $variableName: $variableName+ -#{$breakpoint};
    }
    @include media-breakpoint-up($breakpoint) {
      .#{$variableName} {
        padding-bottom: $value *100%;
      }
    }
  }
}

@each $breakpoint, $width in $grid-breakpoints {
  @include createImagePadding($breakpoint, $width);
}

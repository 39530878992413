// Overrides for Fotorama css. Check lib/mage/gallery/gallery.less for reference

.fotorama__thumb-border {
  border: 1px solid #C7C9C7 !important;
  background-image: none !important;
}

.fotorama__active {
  .fotorama__dot {
    background-color: #C7C9C7 !important;
    border-color: #C7C9C7 !important;
  }
}

.fotorama__stage {
  max-height: 750px !important;
}

.locator-index-index {
  .mapWrapper {
    height: 500px;
    .map {
      height: 100%;
    }
  }
  .mapSearch {
    display: flex;
    .searchForm {
      margin: 2rem auto;
    }
  }

  .store-list {
    .col {
      margin-bottom: 25px;
      .store {
        height: 100%;
        padding: 1rem;
        background-color: #fbfbfb;
        &:hover {
          background-color: #262626;
          color: white;
          p, h3,a {
            color: white;
          }
        }
        .store-address {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
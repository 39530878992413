@import "../abstracts/mixins";
@import 'header';
@import '../base/base';
@import '~bootstrap/scss/mixins/breakpoints';
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_functions";

@mixin section-mixin($background-color:#F7F8F9, $color:#464141) {
  .headline {
    background-color: $background-color;
    color: $color;
    padding: 0.5rem;
    @extend .text-middle;
  }

  .section {
    .section-title {
      @extend .headline;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
      }
    }
  }

  .customer-account-index,
  .customer-account-edit,
  .newsletter-manage-index {
    .page-header {
      padding-left: 0.2rem;
    }
  }

  .customer-address-index,
  .customer-address-form {
    .page-header {
      padding-left: 0.3rem;
    }
  }
  .customer-account-create,
  .customer-account-login,
  .customer-account-index,
  .customer-account-edit,
  .newsletter-manage-index,
  .customer-address-form,
  .catalogsearch-result-index {
    legend {
      @extend .headline;
    }
  }

  .customer-address-index {
    .box-title, .block-title {
      @extend .headline;
    }
  }

  .checkout-steps {
    .step-title {
      @extend .headline;
    }
  }
}

@mixin margin-default($direction) {
  @if ($direction == bottom) {
    margin-bottom: $margin-default;
  } @else if ($direction == right) {
    margin-right: $margin-default;
  } @else if ($direction == left) {
    margin-left: $spacing-medium;
  }
}

@mixin link-content() {
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      text-decoration: none;
      font-weight: bold;
    }

    &:active, &:focus {
      text-decoration: none;
    }
  }
  h2 {
    text-align: center;
    margin: auto;
    color: theme-color('primary');
  }
}

@mixin create-layout($logo-height,$item-size,$item-padding) {
  @include create-navigation-container($logo-height, $item-size, $item-padding);
  $navigation-height: $logo-height-md;

  &.cms-index-index {
    .page-wrapper {
      main {
        padding-top: 0;
        margin-top: -1px;
      }
    }
  }
  //&:not(.cms-index-index)
  //{
  //  .page-wrapper {
  //    main {
  //      //padding-top: calc(#{$header-top-margin} - 1px);
  //    }
  //  }
  //}
  .page-wrapper {
    display: flex;
    flex-flow: column;

    @include create-notification();

    header {
      flex: 0 1 auto;
    }

    main {
      flex: 1 1 auto;

      &.container-fluid {
        width: 100%;
      }

      &.container {
        margin-top: $navigation-height;
      }

      @include media-breakpoint-up(sm) {
        .container {
          width: 100%;
          color: black;
        }
      }
    }
  }
}

html {
  height: 100%;
  @include isIE11() {
    height: inherit;
  }
}

body {
  overflow-x: visible;
}

body {
  @include create-layout($logo-height-lg, $item-size, $item-padding);
  @include media-breakpoint-down(xs) {
    @include create-layout($logo-height, $item-size, $item-padding);
  }
  @include isIE11() {
    .navigation-container .navigation {
      justify-content: space-around;
    }
  }
}

.container {
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: 90%;
    max-width: 100%;
  }
}

.content {
  &.background-link-content {
    @include link-content();
  }
}

.row {
  &.row-product, &.row-login {
    margin-bottom: 1rem;
  }

  &.row-dashboard {
    margin: 1rem 0 1rem 0;
  }

  &.row-fluid {
    .col {
      padding: 0;
    }
  }

  &.row-category-display {
    .col {
      padding: 14px 15px;
    }
  }
}

.no-display {
  display: none;
}

.message {
  &.warning {
    color: orange;

    span {
      color: orange;
    }
  }
}

.popup-authentication {
  display: none;
}

.alert {
  padding: 0.35rem 0.5rem;
  border-radius: 0;

  &.alert-success {
  }

  &.alert-error {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
  }
}

@include section-mixin();

@import "../base/typography";
@import "../layout/main";

.row-content {
  padding: $padding-default 10% $padding-default 10%;
}

.category-description {

}

.content {
  &.category-content {
    @include link-content;
  }
}

.tax-details {
  &.text-muted {
    font-size: 0.85rem;
    margin-bottom: $margin-medium;
  }
}

.product-picture-container {
  margin-bottom: $margin-medium;
}

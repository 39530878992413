.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.no-pr {
  padding-right: 0 !important;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin create-overlay($blur, $backgroundColor, $left:0, $right:0){
  position: fixed;
  top: 0;
  left: $left;
  right: $right;
  bottom: 0;
  background-color: $backgroundColor;
}

@mixin create-flex-complete($direction: row, $wrap: nowrap, $grow: 0, $shrink: 0, $basis: auto, $justify: normal, $align: normal) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  @include create-flex($grow, $shrink, $basis);
  justify-content: $justify;
  align-items: $align;
}

@mixin create-col($col) {
  $percent: ($col / 12) * 100%;
  @include create-flex(0, 0, $percent);
  @include col-basic();
  max-width: $percent;
}

@mixin col-basic() {
  padding-right: 15px;
  padding-left: 15px;
}

@mixin create-flex($grow,$shrink,$width) {
  -webkit-box-flex: 0;
  -webkit-flex: $grow $shrink $width;
  -ms-flex: $grow $shrink $width;
  flex: $grow $shrink $width;
}

@mixin create-dropdown($display,$margin-top:0.5rem,$padding:0,$background-color:transparent) {
  .dropdown {
    z-index: 100;
    position: absolute;
    display: $display;
    padding: $padding;
    background-color: $background-color;
    margin-top: $margin-top;
    cursor: default !important;
  }

  .dropdown-left {
    left: 0;
  }

  .dropdown-right {
    right: 0;
    box-shadow: 1px 1px 1px -1px #ccc;
  }

  .dropdown-border {
    border: 1px solid rgba(0,0,0,0.15);
  }

  .dropdown-full {
    width: 100%;
  }
}

@mixin create-dropdown-container($container-padding,$background-color, $margin-top: 0.5rem) {
  .dropdown-container {
    white-space: nowrap;
    position: relative;
    @include create-dropdown(none);

    &.active-dropdown {
      @include create-dropdown(block, $margin-top, $container-padding, $background-color)
    }

    ul {
      margin: 0;
    }
  }
}

@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../components/image";

.category-sets,.category-gifts {
  .product {
    &.sets {
      .product-image {
        padding-bottom: get-padding-by-ratio("7-5")
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
}
@import '../../components/products-display';
@import '../../components/variables';

@mixin quantity-select() {
  select,
  input {
    border: 1px solid $btn-primary-color;
    border-right: none;
    text-align: center;
    height: 100%;
  }

  select {
    height: 100%;
    text-align-last: center;

    &:focus {
      box-shadow: unset;
    }
  }
}

.catalog-product-view {

  .column {
    margin-top: 2rem;
  }

  .row-product-container {
    //Product description information (check product.xml for classes structure)
    .product-content {
      .product-top-content {
        margin-bottom: 1.75rem;

        .product-full-name {
          .attribute {
            &.decor {
              font-size: 1.3rem;
            }

            &.name {
              font-size: 1.6rem;
            }
          }
        }

        .product-info-price {
          line-height: .75;
          font-size: 1.6rem;
        }
      }

      .product-main-actions {
        .product-quantity {
          padding-right: 0;

          @include quantity-select;
        }

        .product-to-cart {
          padding-left: 0;
        }

        .btn {
          width: 100%;
        }
      }

      // Attributes
      .product-attributes-description-wrapper {
        margin-top: .75rem;

        .product-attributes-description-content {

          .description-header {
            text-transform: uppercase;
          }

          .product-details {
            margin: 1rem 0;

            .product-detail-item {
              margin: 1rem 0;

              .attribute-group {


                &-title {
                  text-transform: uppercase;
                }

                .attribute-group-content {
                  padding: 0 0 1rem 0;
                }

                dt {
                  font-weight: normal;
                }

                &[aria-expanded=false] {
                  .attribute-group-content {
                    padding: 0;
                  }
                }

                &[aria-expanded=false]:before,
                &[aria-expanded=true]:before {
                  display: inline-block;
                  min-width: 8px;
                }

                &[aria-expanded=false]:before {
                  content: '+';
                }

                &[aria-expanded=true]:before {
                  content: '-';
                }
              }
            }
          }
        }
      }

      .table-grouped-header {
          border-bottom: 2px solid $gray-600;
      }

      .table-grouped-content {
          border-top: 1px solid $gray-600;
      }
    }

    //Footer
    .tax-footer {
      text-align: left;
    }

    //  Responsive
    @include media-breakpoint-down(sm) {
      .product-actions {
        justify-content: center;
      }

      .product-attributes-description-content {
        text-align: center;
      }

      .tax-footer {
        text-align: center;
      }
    }
  }
}

// Related and upsell

.crosssell-wrapper,
.related-wrapper,
.upsell-wrapper {
  @include products-display;
  margin-top: 2rem;

  .title {
    margin-bottom: 1rem;

    & > div {
      text-align: center;
      text-decoration: underline;
    }
  }
}

// Newsletter (could be extracted if it will be reused later ?)

.product-newsletter {
  margin: 2rem 0 0 0;

  .information-block-wrapper {
    background-color: #C7C9C7;
  }

  .information-block-wrapper,
  .subscribe-form-wrapper {
    padding: 5rem;
  }

  .information-block,
  .subscribe-form {
    justify-content: center;
  }

  .subscribe-form {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .information-block {
    color: #ffffff;

    .newsletter-title {
      font-size: 3.5rem;
      line-height: 1;

    }
  }

  //  Responsive
  @include media-breakpoint-down(md) {
    .information-block-wrapper,
    .subscribe-form-wrapper {
      padding: 3.5rem;
    }

    .newsletter-title {
      font-size: 3rem !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .information-block-wrapper,
    .subscribe-form-wrapper {
      padding: 2.5rem;
    }

    .newsletter-title {
      font-size: 2.5rem !important;
    }
  }
}


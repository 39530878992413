@import "../abstracts/mixins";
@import '../base/base';
@import '~bootstrap/scss/mixins/breakpoints';
.cms-index-index {
  h2 {
    font-size: 3.0em;
  }
  .navigation-icon {
    background-color: transparent;
  }
  img {
    max-width: 100%;
  }
  .newsletter-and-shop {
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 100%;
    .shop-image {
    }
    .newsletter-sign-up {
      padding: 0.75rem;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      > div {
        margin: auto;
        &.script {
          width: 75%;
          margin-top: 3rem;
          margin-bottom: 2rem;
          img {
            width: 100%;
          }
        }
      }
      .form-group {
        text-align: center;
        &.subscription {
          input::placeholder {
            text-align: center;
          }
          input::-webkit-input-placeholder {
            text-align: center;
          }
          input::-moz-placeholder {
            text-align: center;
          }
          input:-ms-input-placeholder {
            text-align: center;
          }
        }
      }
    }
  }
  .form-group:last-child {
    margin-bottom: 0;
  }
}

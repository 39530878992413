@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/forms";
@import "variables";
@import "../variables";

.form {
  display: flex;
  flex-wrap: wrap;

  .form-group, .form-fieldset {
    flex: 0 0 100%;
  }

  .form-fieldset {
    flex-wrap: wrap;

    .form-group {
      flex: 0 0 100%;

      &.additional {
        flex: 0 1 auto;
      }

      .range.price {
        display: flex;
        justify-content: space-between;

        .field.no-label {
          flex: 0 0 40%;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.form-lg-col-2 {
      margin-left: -0.5rem;
      margin-right: -0.6rem;

      .form-group {
        padding: 0 0.5rem 0 0.5rem;
      }

      .form-group, .form-fieldset {
        flex: 0 0 50%;
      }

      .form-fieldset {
        .form-group {
          flex: 0 0 70%;

          &.additional {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &.form-flex-inline {
    .form-group {
      flex: 1 0 auto;
    }
  }
}

.form-control {
  border-width: 0;
  border-bottom-width: 1px;
  padding-left: 0;
  padding-right: 0;
}

.input-text {
  @extend .form-control;
}

.btn-group {
  > .btn {
    margin-right: 1rem;
  }
}

.form-group {
  label {
    color: $element-color;
  }
}

.field-tooltip {
  &.toggle {
    .field-tooltip-content {
      display: none;
    }

    &._active {
      .field-tooltip-content {
        display: block;
      }
    }
  }

  .field-tooltip-content {
    img {
      max-width: 100%;
    }
  }
}

select {
  &.form-control:not([size]):not([multiple]) {
    height: 2.2666666rem;
  }
}

textarea {
  &.form-control {
    background-color: whitesmoke;
  }
}

input[type=radio], input[type=checkbox] {
  height: 1.2rem;
  width: 1.2rem;
  vertical-align: middle;
  margin: 0 0.5rem;
}

.mage-error {
  color: $error-color;

  &.form-control {
    border-color: $error-color;
  }
}

.form-group {
  &._error {
    .form-control {
      border-color: $error-color;
    }

    label {
      //color: $error-color;
    }
  }

  &._warn {
    .form-control {
      border-color: orange;
    }
  }
}

.form-group-information {
  padding-bottom: 0.4rem;
}

.form-group {
  .error-wrapper {
    input {
      border-color: $error-color;
    }
  }
}

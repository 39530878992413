$font-family_meta-light: Meta-Light;
$font-family_meta-pro-normal: MetaPro-Normal;
$font-family_meta-normal-roman: MetaNormal-Roman;

$font-family_didot-regular: 'Didot eText W01 Regular';
$font-family_didot-italic: 'Didot eText W01 Italic';

$font-family_fallbacks: Segoe UI Light, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;

$text-color: #212529;
$text-muted-color: #b7b8bb;

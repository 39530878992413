@import "variables";
@import "~bootstrap/scss/bootstrap.scss";


@import
'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

@import
'base/base',
'base/fonts',
'base/typography',
'base/helpers';

/*4. Layout-related sections*/
@import
'layout/search',
'layout/footer',
'layout/main',
'layout/_header-content.scss';

/*5. Components*/
@import
'components/button',
'components/scroll-to-top',
'components/form',
'components/image',
'components/cmspage',
'components/image',
'components/germanlaw',
'components/messages',
'components/gallery',
'components/products-display',
'components/slick',
'components/giftcards';

/*6. Page-specific styles*/
@import
'pages/stories',
'pages/home',
'pages/shop',
'pages/product',
'pages/category',
'pages/catalog/product',
'pages/catalog/search',
'pages/storelocator',
'pages/cart',
'pages/account',
'pages/search',
'pages/checkout',
'pages/blog',
'pages/catalog/sets-and-gifts',
'pages/brand',
'pages/advancedsearch';


/*7. Themes*/
@import
'themes/default';

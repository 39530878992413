@import "../components/products-display";

.catalogsearch-result-index {
  @include products-display();

  .search {
    .content a {
      width: 100%;
      height: 100%;
    }
  }

  .query-search-text,
  .results {
    margin-top: 1rem;
  }
}

.catalogsearch-advanced-result {
  .search {
    .product {
      text-align: center;

      .product-description a {
        color: #454040;
        text-decoration: none;
      }
    }

    .content a {
      width: 100%;
      height: 100%;
    }

    &.results {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

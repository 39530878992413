@import "cart";
@import "../layout/main";
@import "../base/typography";
@import '~bootstrap/scss/mixins/breakpoints';
@import "~bootstrap/scss/_variables";

.checkout-index-index {

  @include create-cart-items(3, 0.5rem, #ccc);
  .checkout-step-additional-information {
    .step-content {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      p {
        margin-bottom: 0.5rem;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  .checkout-step {
    &.checkout-shipping-address {
      // HACK
      div[name="shippingAddress.region"] {
        display: none;
      }
      .shipping-address-items {
        .shipping-address-item {
          padding-top: 0.5rem;
          padding-bottom: 0.7rem;
          margin: 0;
          .address-information {
            span:after {
              content: '.';
            }
          }
        }
        margin-bottom: 0.5rem;
      }
      .shipping-content-actions {
        text-align: right;
        padding: 1rem 0;
        border-top: 1px solid #ccc;
      }
    }
    .shipping-information {
      button {
        margin: 0.5rem 0;
      }
    }
    .step-content, .shipping-information, opc-block-summary {
      padding-left: 0.1rem;
      padding-right: 0.1rem;
    }
    .opc-block-shipping-information {
      margin-bottom: 0.5rem;
    }
    .actions-toolbar {
      text-align: right;
      .secondary {
        margin-top: 0.3rem;
      }
    }
    &.checkout-step-summary {
      .step-content {
        padding: 0;
      }
    }
    &.checkout-shipping-method {
      .shipping-form {
        .shipping-methods {
          > .shipping-method {
            padding: 1rem 0 1rem 0;
            border-bottom: 1px solid #ccc;
            display: flex;
            flex-wrap: nowrap;
            > div {
              margin: auto;
              flex: 1 1 auto;
              &.shipping-select {
                flex: 0 0 5%;
                cursor: pointer;
              }
              &.shipping-price {
                flex: 0 0 20%;
                text-align: right;
              }
            }
          }
        }
        .shipping-actions {
          margin-top: 1rem;
        }
      }
    }
    &.checkout-step-payment {
      .payment-selection {
        margin-top: 1rem;
      }
      .opc-payment {
        width: 100%;
      }
    }
  }
  .checkout-shipping-address {
    .shipping-address-items {
      display: flex;
      flex-wrap: wrap;
      @include margin-default(bottom);
      > .shipping-address-item {
        @include margin-default(right);
      }
    }
  }
  .opc-block-shipping-information {
    > .shipping-information {
      display: flex;
      padding-top: 0.5rem;
      > .shipping-address {
        flex: 1 1 auto;
      }
    }
  }
  .opc-block-summary {
    .totals-container {
      display: flex;
      flex-wrap: wrap;
      .totals {
        padding: 0.1rem 0;
        box-sizing: border-box;
        flex-grow: 1;
        overflow: hidden; // Or flex might break
        &.totals-grand {
          @extend .text-middle;
        }
        &.totals-amount {
          text-align: right;
        }
      }
    }
  }
  .payment-group {
    display: flex;
    flex-flow: wrap;
    .payment-method {
      flex: 0 0 100%;
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        padding: 0 15px;
      }
      .payment-method-title {
        input {
          &.radio {
            cursor: pointer;
          }
        }
        label {
          &.label {
            cursor: pointer;
          }
        }
      }
      .payment-method-content {
        display: none;
      }
      &._active {
        .payment-method-content {
          display: block;
        }
      }
    }
  }
  .hosted-control {
    display: block;
    width: 100%;
    padding: .5rem 0;
    font-size: 1rem;
    line-height: 1.25;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    height: 34px;
    border-bottom: 1px solid #969595;
    iframe {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  .inputIframe {
    @extend .hosted-control;
  }

  .control {
    @extend .form-group;
    margin-bottom: 0.25rem;
  }

  .card-expiration-group {
    display: flex;
    flex-wrap: wrap;

    > label {
      flex: 0 0 100%;
    }

    > .form-group {
      flex: 0 1;
      margin-right: 0.25rem;
    }
  }

}

.checkout-steps {
  .payment-selection {
    .payment-method {
      min-height: 2rem;
      margin-top: 1rem;
      padding: 0 .5rem;
      .payment-method-content {
        margin-top: 1rem;
        .actions-toolbar {
          button {
            width: 100%;
          }
        }
      }
      .payment-method-header {
      }
    }
  }
  .checkout-billing-address {
    .checkout-billing-address-label {
      height: 2rem;
    }
    .btn-group {
      display: flex;
      justify-content: flex-end;
      button {
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }
  .checkout-step-payment {
    .step-content {
      margin-top: 0.3rem;
      .opc-payment-additional {
        &.discount-code {
          margin-top: 3rem;
          form {
            justify-content: flex-end;
            .messages {
              @extend .mage-error;
            }
            button {
              margin-left: 1rem;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .step-title {
    clear: both;
  }
  .step-content {
    .opc-block-summary {
      .grand-total {
        font-size: 1.2rem;
        padding-top: 0.1rem;
      }
      .totals-description {
        text-align: right;
        &:after {
          content: ':';
        }
      }
      a {
        .btn-default {
        }
        margin-top: 1rem;
        margin-bottom: 1rem;
        float: right;
      }
    }
    .shipping-actions {
      button {
        float: right;
        padding-left: 1rem;
      }
    }
  }
  .row-cart-item {
    font-size: 1.1rem;
  }
}

.checkout-form {
  display: flex;
  flex-wrap: wrap;
  > .form-group, .form-fieldset {
    flex: 0 0 100%;
    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
    }
  }
  > .form-fieldset {
    > .control {
      display: flex;
      flex-wrap: wrap;
      .form-group {
        flex: 1 1 100%;
        @include media-breakpoint-up(lg) {
          flex: 1 0 auto;
        }
        &.additional {
          flex: 0 0 70%;
          @include media-breakpoint-up(lg) {
            flex: 0 0 30%;
          }
        ;
        }
      }
    }
  }
  .form-group {
    padding: 0 0.5rem 0 0.5rem;
    margin-bottom: 0.25rem;
    .form-group-information {
      margin-bottom: 1rem;
    }
  }
  .form-control {
    border-width: 0;
    border-bottom-width: 1px;
  }
}

.checkout-step-summary {
  .modal-header {
    display: none;
  }
  .modal-content {
    border: none;
    border-radius: 0;
  }
  .opc-sidebar {
    outline: 0;
    &:focus, &:active {
      outline: 0;
    }
  }
}

.checkout-onepage-success {
  .page-title-wrapper {
    a {
      &.print {
        &:after {
          content: ' >';
        }
      }
    }
  }
  .column {
    &.main {
      margin-top: 4rem;
    }
    .checkout-success {
      p:first-child {
        font-size: 1.4rem;
        span:first-child {
          font-weight: bold;
        }
      }
      .actions-toolbar {
        a {
          &.primary {
            :after {
              content: ' >';
            }
          }
        }
      }
    }
    .registration {
      margin-top: 2rem;
    }
  }
}

@import "~typi/scss/typi";
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/_variables';
@import "variables";

// Configure typi map
$base-font-size: 15px;
$h1-base-font-size: $base-font-size * 2.5;
$h2-base-font-size: $base-font-size * 2;
$h3-base-font-size: $base-font-size * 1.5;
$h4-base-font-size: $base-font-size * 1.2;
$xs-scaling: 0.95;
$xl-scaling: 1.05;
$typi-hering: (
        base: (
                null: ($base-font-size, 1.4),
                xs: ($base-font-size * $xs-scaling, 1.4 * $xs-scaling),
                sm: ($base-font-size, 1.4),
                md: ($base-font-size, 1.4),
                lg: ($base-font-size, 1.4),
                xl: ($base-font-size * $xl-scaling, 1.4 * $xl-scaling)
        ),
        h1: (
                null: ($h1-base-font-size, 1.4),
                xs: ($h1-base-font-size * $xs-scaling, 1.4 * $xs-scaling),
                sm: ($h1-base-font-size, 1.4),
                md: ($h1-base-font-size, 1.4),
                lg: ($h1-base-font-size, 1.4),
                xl: ($h1-base-font-size * $xl-scaling, 1.4 * $xl-scaling)
        ),
        h2: (
                null: ($h2-base-font-size, 1.4),
                xs: ($h2-base-font-size * $xs-scaling, 1.4 * $xs-scaling),
                sm: ($h2-base-font-size, 1.4),
                md: ($h2-base-font-size, 1.4),
                lg: ($h2-base-font-size, 1.4),
                xl: ($h2-base-font-size * $xl-scaling, 1.4 * $xl-scaling)
        ),
        h3: (
                null: ($h3-base-font-size, 1.4),
                xs: ($h3-base-font-size * $xs-scaling, 1.4 * $xs-scaling),
                sm: ($h3-base-font-size, 1.4),
                md: ($h3-base-font-size, 1.4),
                lg: ($h3-base-font-size, 1.4),
                xl: ($h3-base-font-size * $xl-scaling, 1.4 * $xl-scaling)
        ),
        h4: (
                null: ($h4-base-font-size, 1.3),
                xs: ($h4-base-font-size * $xs-scaling, 1.3 * $xs-scaling),
                sm: ($h4-base-font-size, 1.3),
                md: ($h4-base-font-size, 1.3),
                lg: ($h4-base-font-size, 1.3),
                xl: ($h4-base-font-size * $xl-scaling, 1.3 * $xl-scaling)
        )
);

// Render base media queries
// Set custom size map and breakpoint map from bootstrap, typi-init is rendering html element automatically
@include typi_init($typi: $typi-hering, $breakpoints: $grid-breakpoints);
// Render base font sizes additionally in body element to override default bootstrap media queries
@include typi-base($selector: 'body');

html {
  font-family: $font-family_meta-light + ',' + $font-family_fallbacks;
}

h1 {
  @include typi('h1', $typi: $typi-hering);
  text-transform: uppercase;
}

h2 {
  @include typi('h2', $typi: $typi-hering);
}

h3 {
  @include typi('h3', $typi: $typi-hering);
}

.text-heading {
  @extend h2;
  font-weight: normal;
  text-transform: uppercase;
}

.text-middle {
  @extend h3;
  font-weight: normal;
}

.text-sub-heading {
  font-weight: normal;
  text-transform: uppercase;
}

.text-muted {
  color: $text-muted-color !important;
}

// -----------------------------------------------------------------------------
// type stuff
// -----------------------------------------------------------------------------
button, .btn {
  font-family: $font-family_meta-normal-roman + ',' + $font-family_fallbacks;
}

.product-content {
  .attribute {
    &.delivery-times {
      font-family: $font-family_meta-normal-roman + ',' + $font-family_fallbacks;
    }
  }
}
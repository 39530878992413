@import "~bootstrap/scss/mixins/breakpoints";
@import "../layout/main";
@import "../components/cmspage";
@import "../components/scroll-to-top";

.page-layout-brand {
  .nav-fixed-lg {
    @include media-breakpoint-up(lg) {
      position: fixed;
    }
  }
  .nav-item {
    .nav-item {
      line-height: 1rem;
    }
  }
  .anchor {
    display: block;
    position: relative;

    top: (-$logo-height - 1);
    @include media-breakpoint-up(lg) {
      top: (-$logo-height-lg - 1);
    }
    visibility: hidden;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  .nav-item {
    .nav-link {
      color: #262626;
      &.first {
        font-size: 1.4rem;
        text-transform: uppercase;
      }
      &.inactive {
        color: #969595;
        &:hover, &:target {
          color: #262626;
        }
      }
    }
  }
  .brand-content {
    header {
      padding-bottom: 0.3rem;
      padding-top: 0.1rem;
      font-style: italic;
    }
    @extend .cms-content;
    a.contact-link:after {
      content: ' >';
    }
    #contact-form {
      margin-top: 2rem;
    }
    > div:not(.anchor) {
      margin-bottom: 2.5rem;
    }
    overflow: auto;
    h2 {
      margin-top: 1rem;
    }
    h3 {
      text-transform: uppercase;
      font-size: 1.3rem;
    }
    h4 {
      font-size: 1.3rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
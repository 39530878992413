@import "../base/typography";
@import "../base/base";
@import "../components/notification";
@import '~bootstrap/scss/mixins/breakpoints';
@import "~bootstrap/scss/_variables";
@import "../base/helpers";

$navigation-primary-color: black;

@mixin create-icon($size) {
  height: $size;
  width: auto;
}

@mixin create-navigation-icon($height) {
  .navigation-icon {
    background-color: transparent;

    img {
      height: $height;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@mixin create-navigation-container($logo-height,$item-size,$item-padding) {
  $navigation-height: $logo-height + ($item-padding * 2);

  &.cms-index-index {
    &.nav-trans-end {
      .navigation-container {
        .navigation-background {
          &.small {
            transition: none !important;
          }
        }
      }

      .navigation {
        .item {
          &.item-center {
            transition: none !important;
          }
        }
      }
    }
  }
  &.cms-index-index {
    .navigation-container {
      padding-top: 0 !important;

      .navigation-background {
        height: 0;

        &.small {
          height: $header-top-margin;
        }
      }

      background-color: transparent;

      .navigation {
        .item {
          &.item-center {
            height: $logo-height;

            &.small {
              height: $logo-height-sm;
            }
          }
        }
      }
    }
  }

  &:not(.cms-index-index) {
    .navigation-container {
      .navigation-background {
        height: $header-top-margin;
      }

      background-color: white;

      .navigation {
        .item {
          &.item-center {
            height: $logo-height-sm !important;

            #navigation-logo-container {
              .navigation-logo {
                height: 118% !important;
              }
            }
          }
        }
      }
    }
  }

  .navigation-container {
    .navigation-background {
      position: absolute;
      width: 100%;
      background-color: white;
      box-shadow: 0 2px 1px -1px #ccc;
      z-index: -2;
      transition: height 200ms ease-in-out;
    }

    .navigation {
      &.container {
        box-shadow: 0px 1px 1px -1px #ccc;
        background-color: #f8f8f8;
        width: 100%;
        max-width: 100%;
        position: absolute;
        z-index: -3;
        margin-top: -$item-padding;
        padding-top: 0 !important;
        transition: height 250ms ease-in-out !important;

        .wrapper {
          margin-top: 4rem;
        }
      }

      .item {
        &.item-center {
          margin-top: -0.25rem;
          position: absolute;
          left: 50%;
          width: auto;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
          z-index: -2;
          transition: height 200ms ease-in-out;

          #navigation-logo-container {
            display: inline-block;
            overflow: hidden;
            height: 100%;

            .navigation-logo {
              height: 100%;
              transition: height 200ms ease-in-out;

              &.small {
                height: 118%;
              }
            }
          }
        }
      }
    }
  }

  .navigation-container {
    padding: 0 0 $item-padding 0;
    width: 100%;

    @include create-notification();
    @include create-navigation-icon($item-size);

    .navigation {
      .wrapper {
        margin-top: 2rem;
        width: 100%;

        .search-form {
          margin-top: 0.4rem;
          border-bottom: 1px solid #000;
          padding-bottom: 0.4rem;

          input::placeholder {
            text-align: center;
            text-transform: uppercase;
          }

          input::-webkit-input-placeholder {
            text-align: center;
            text-transform: uppercase;
          }

          input::-moz-placeholder {
            text-align: center;
            text-transform: uppercase;
          }

          input:-ms-input-placeholder {
            text-align: center;
            text-transform: uppercase;
          }

          #search {
            text-align: center;
            padding-left: 0.5rem;
            border: 0;
            color: #000;
          }

          .search-submit {
            border: 0;
            cursor: pointer;
            color: transparent;
            background-repeat: no-repeat;
            height: 1.7rem;
            width: 1.7rem;
            position: absolute;
            right: 12px;
            top: 4px;
          }
        }
      }

      padding: $item-padding 1.4rem 0 1.4rem;
      @include media-breakpoint-down(md) {
        padding: $item-padding 1rem 0 1.1rem;
      }
      display: block;
      text-align: left;

      &.container {
        padding: 0 0.5rem 0 0.5rem;
      }

      &.collapse {
        display: none;

        &.show {
          display: flex;
        }
      }

      .item-container {
        display: flex;
        z-index: 1;

        &.item-container-left {
          margin-right: auto;
          float: left;

          .navigation-icon {
            text-align: left;
          }

          > div {
            margin: auto;
            padding-right: 1rem;
            @include media-breakpoint-down(sm) {
              padding-right: 0.85rem;
            }
            @include media-breakpoint-down(xs) {
              padding-right: 0.70rem;
            }
          }
        }

        &.item-container-right {
          margin-left: auto;
          float: right;

          .navigation-icon {
            text-align: right;
            position: relative;
          }

          > div {
            margin: auto;
            padding-left: 1rem;
            @include media-breakpoint-down(sm) {
              padding-left: 0.85rem;
            }
            @include media-breakpoint-down(xs) {
              padding-left: 0.70rem;
            }
          }
        }

        .basket-icon {
          height: $item-size;
          width: $item-size;
          line-height: $item-size;
          margin-top: -12%;

          &:hover {
            cursor: pointer;
          }
        }

        .lang-icon {
          height: 120%;
          margin-top: -6%;

        }
      }

      .menu-container {
        display: flex;
        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
        }

        .image-container {
          &.empty {
            display: none;
          }

          flex-grow: 1;
          flex: 40%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
          margin: 0.8rem 0.6rem 1.3rem 0.6rem;
          @include media-breakpoint-down(md) {
            margin: 0.5rem .4rem 1rem .4rem;
          }
          @include media-breakpoint-down(xs) {
            background-size: cover;
            flex: 100%;
            min-height: 180px;
            margin-top: 0;
            background-position: center center;
          }
        }

        .list-container {
          flex: 60%;
          display: inline-block;
          flex-grow: 1;
          position: relative;

          .item {
            margin-top: 0;
            margin-bottom: 0;

            &.item-list {
              list-style: none;
              display: inline-block;
              padding: 0.6rem 1rem 1rem 1rem;
              @include media-breakpoint-down(md) {
                padding: 0.1rem 0.5rem 0.5rem 0.7rem;
              }
              font-size: 1.2rem;

              .navigation-item {
                white-space: nowrap;

                a {
                  &.trigger {
                    position: relative;


                    &:hover {
                      font-weight: bold;
                    }
                  }

                  cursor: pointer;
                  text-decoration: none;
                }

                .arrow {
                  // Override Segoe Emoji font in MS Edge
                  font-family: 'Segoe UI Symbol';
                  cursor: default;
                  font-size: 0.8rem;
                  padding-left: 0.25rem;
                  color: $text-muted-color;
                  display: inline-block;
                  padding-bottom: 0.1rem;
                  vertical-align: middle;
                  line-height: 1.2rem;
                }

                &.active {
                  a {
                    &.trigger {
                    }
                  }

                  .arrow {
                    color: $text-color;
                  }
                }

                .item-sublist {
                  transition: max-height 300ms ease-in-out, opacity 200ms ease-in-out;
                  list-style: none;
                  left: 8rem;
                  opacity: 0;
                  max-height: 0;
                  overflow: hidden;
                  display: block !important;

                  &.items-1 {
                    margin-top: calc((-#{$item-size} * 1) / 2 - #{$item-size} / 2);
                  }

                  &.items-2 {
                    margin-top: calc((-#{$item-size} * 2) / 2 - #{$item-size} / 2);
                  }

                  &.items-3 {
                    margin-top: calc((-#{$item-size} * 3) / 2 - #{$item-size} / 2);
                  }

                  &.items-4 {
                    margin-top: calc((-#{$item-size} * 4) / 2 - #{$item-size} / 2);
                  }

                  &.items-top {
                    margin-top: calc(-#{$item-size});
                  }

                  &.items-bottom-3 {
                    margin-top: calc(-#{$item-size} * 2);
                  }

                  position: absolute;
                }

                &.active {
                  .item-sublist {
                    opacity: 1;
                    max-height: 200px;
                  }
                }

                height: $item-size;
              }

              .navigation-link {
                cursor: pointer;
                text-decoration: none;
                height: $item-size;
                line-height: $item-size;

                &.current {
                  font-weight: bold;
                  cursor: default;

                  &.has-children {
                    cursor: pointer;
                  }
                }

                &:hover {
                  text-decoration: none;
                  font-weight: bold;
                }
              }

              .list-link {
                display: inline-block;
                width: $item-size;
                height: $item-size;
                text-align: center;
                vertical-align: middle;

                img {
                  width: $item-size;
                }

                &:hover {
                  cursor: pointer;
                }
              }

              .point {
                margin: 0.25rem 0.5rem 0.25rem 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  @include create-dropdown-container(0.75em, $dropdown-background);
}

.top-nav {
  position: relative;

  .item-reset {
    height: auto;
  }
}

@mixin create-icon($size) {
  height: $size;
  width: auto;
}

@mixin create-navigation-icon($height) {
  .navigation-icon {
    background-color: transparent;
    height: $height;

    img {
      height: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

#b2b_button {
  position: fixed;
  top: calc(#{$header-top-margin} + 65px);
  right: 30px;
  border: 1px solid #c7c9c7;
  color: #c7c9c7;
  text-align: center;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 100px;
  height: 100px;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(230, 230, 230, 0.25);

  &:hover {
    background-color: rgba(155, 155, 155, 0.25);
  }
}

@media only screen and (min-width: 768px) {
  #b2b_button {
    width: 140px;
    height: 140px;
    font-size: 15pt;
  }
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the scroll-to-top component.
// -----------------------------------------------------------------------------
@import "variables";

#scroll-to-top {
  background-image: url('data:image/svg+xml;utf8,<svg fill="#FFFFFF" height="32" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  z-index: 1000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  width: 50px;
  height: 50px;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#scroll-to-top:hover {
  background-color: #000;
}
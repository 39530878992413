@import "../base/_variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/functions";

.blog-index-index, .blog-post-view {
  background-color: white;

  .action {
    &.tocompare {
      display: none;
    }
  }

  .page-main {
    .row:first-child {
      margin-top: 2rem;
    }
  }

  .page-wrapper {
  }

  .page-title-wrapper, .post-read-more, .post-header {
    display: none;
  }

  .post-list-wrapper, .post-view {
    font-family: unquote($font-family_didot-regular) + "," + $font-family_fallbacks;
    font-size: 1.5rem;
    margin: 0 15%;
    cursor: default;

    .post-holder {
      background: none;
    }

    .post-holder:hover {
      box-shadow: none;
    }

    .post-list {
      margin: 0;
      padding: 0;

      .post-holder:not(:last-child) {
        padding: 0;
        border-bottom: 1px solid theme-color('secondary');
        margin-bottom: 10px;
      }
    }
  }

  .post-content .post-description img {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .post-list-wrapper {
    .box {
      margin-bottom: 2.5rem;
      font-size: 1.3rem;
      line-height: 2.3rem;
    }

    .no-space {
      margin-bottom: 0;
    }

    .box2 {
      @extend .box;
      @extend .col-12;
      @extend .col-lg-6;
    }

    .box3 {
      @extend .box;
      @extend .col-12;
      @extend .col-md-4;
      margin-bottom: 0;
      padding: 0;
    }

    .box2-img {
      @extend .box2;
      background: no-repeat center;
      background-size: cover;
      flex: 1;
      margin-bottom: 0;
      min-height: 25rem;

      img {
        padding-top: 0.5rem;
        margin-bottom: 2.5rem;
      }
    }

    .box2-black-italic {
      @extend .box2;
      font-family: unquote($font-family_didot-italic) + "," + $font-family_fallbacks;
      font-size: 2.46rem;
      line-height: 2.73rem;
      color: #fff;
      background-color: #000;
      text-align: center;
      padding: 2rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .box-large-italic {
      @extend .box;
      font-family: unquote($font-family_didot-italic) + "," + $font-family_fallbacks;
      font-size: 3rem;
      line-height: 3.33rem;
      text-align: center;
      color: #262626;
    }
  }

  @include media-breakpoint-down(xs) {
    .post-list-wrapper {
      .box {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }

      .box2-black-italic {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }

      .box-large-italic {
        font-size: 2rem;
        line-height: 2.6rem;
      }
    }
  }
}


@import "../components/image";
@import "../components/products-display";

.catalog-category-view {
  @include products-display;

  .category-description {
    margin-top: $margin-medium;
    p {
      font-size: 1.4rem;
    }
    h1, p {
      text-align: center;
    }
  }
  .special-price {
    color : red !important;
  }
}
.product-image{
  //@include createImagePadding(xs);
  padding-bottom: get-padding-by-ratio("1-1");
}

@import "../base/typography";
@import "../layout/main";
@import '~bootstrap/scss/mixins/breakpoints';
@import "~bootstrap/scss/_variables";

.dropdown {
  ul {
    list-style: none;
    padding: 0;
  }
}

.dropdown-search {

  top: calc(#{$item-padding} / 1.5) !important;
  margin: 0 !important;
  position: absolute;
  padding: 0 !important;
  opacity: 0;
  display: block !important;
  visibility: hidden;
  background-color: white !important;
  transition: opacity 100ms ease-in-out;
  width: calc(100% - (#{$item-size} * 5) - (1rem * 2) - (1rem * 5) - 6px) !important;
  left: calc((#{$item-size} * 2) + (1rem * 3)) !important;
  @include media-breakpoint-down (sm) {
    width: calc(100% - (#{$item-size} * 5) - (1rem * 2) - (0.85rem * 5) - 6px) !important;
    left: calc((#{$item-size} * 2) + (0.85rem * 2) + 1rem) !important;
  }
  @include media-breakpoint-down (xs) {
    width: calc(100% - (#{$item-size} * 5) - (1rem * 2) - (0.7rem * 5) - 6px) !important;
    left: calc((#{$item-size} * 2) + (0.7rem * 2) + 1rem) !important;
  }

  .search {
    width: 100%;

    input {
      padding: 0.5rem 1.8rem 0.4rem 0.2rem;
      font-size: 1.4rem;
      border-color: #eee !important;
      margin-bottom: 0.2rem;
      background-repeat: no-repeat;
      background-position: 99.8% 60%;
      background-size: 20px;

      &:hover, &:focus {
        border-color: #B7B8BB !important;
      }
    }
  }
}

body {
  &.nav-small {
    #navSearch {
      &.active {
        .dropdown-search {
          opacity: 1;
          visibility: visible;
          transition: opacity 100ms ease-in-out, visibility;
        }
      }
    }
  }
}

body {
  &.nav-small {
    &.cms-index-index {
      &:not(.nav-trans-end) {
        #navSearch {
          &.active {
            .dropdown-search {
              opacity: 1;
              visibility: visible;
              transition: opacity 100ms 150ms ease-in-out, visibility;
            }
          }
        }
      }
    }
  }
}




@import "../base/variables";
@import "../components/button";

.customer-account-login {
  .block-content {
    padding: 0 0.1rem;
  }
  .form-login {
    padding: 0 0.1rem;
    .form-group {
      &.actions {
        text-align: right;
        div {
          padding-top: 0.3rem
        }
      }
    }
  }
  .primary {
    .action {
      span:after {
        content: ' >';
      }
    }
  }
}

.customer-account-forgotpassword {
  .row {
    padding: 0 0.3rem;
  }
  .form-text {
    margin-bottom: 1rem;
  }
  .actions-toolbar {
    width: 100%;
    text-align: right;
    button {
      margin-left: 1rem;
    }
    a:before {
      content: '< ';
    }
  }
}

.customer-account-create {
  .newsletter {
    margin-top: 1rem;
    label {
      color: $text-color;
    }
  }
  .create-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #ccc;
    padding-top: 1rem;
    margin: 0 0.5rem;
    button {
      margin: 0 0 0 1rem;
    }
    a:before {
      content: '< ';
    }
    a {
      &.btn-link {
        padding: 0;
      }
    }
  }
}

.customer-account-index {
  .recent-orders {
    .orders-recent {
      .recent {
        margin-bottom: 0;
        th, td {
          padding: 0.5rem;
        }
      }
    }
  }
  .recent-orders {
    .actions-toolbar {
      width: 100%;
      text-align: right;
      .actions {
        padding-top: 1rem;
        border-top: 1px solid #ccc;
      }
    }
  }
  .row {
    &.row-dashboard {
      margin: 0;
      .box {
        margin: 1rem 0 0 0;
      }
    }
  }
  .box-content, .box-actions {
    padding: 0 0.1rem;
  }
  .box-content {
    min-height: 3rem;
  }
  .box-actions {
    text-align: right;
    .btn {
      margin-left: 0.5rem;
    }
  }
}

.table-order-items {
  td {
    &.actions {
      a {
        padding-left: 0;
      }
      a:after {
        content: '>';
      }
    }
  }
}

.newsletter-manage-index {
  .row {
    &.row-dashboard {
      width: 100%;
      .box-content {
        margin-bottom: 1.5rem;
        .choice {
          padding-left: 0.1rem;
        }
      }
    }
  }
  .actions-toolbar {
    width: 100%;
    display: inline;
    text-align: right;
    .actions {
      padding-top: 1rem;
      border-top: 1px solid #ccc;
    }
    button {
      margin: 0 0 0 1rem;
    }
    a:before {
      content: '< ';
    }
  }
}

.customer-address-index {
  .column {
    &.main {
      display: flex;
      flex-wrap: wrap;
    }
    .box-title, .block-title {
      margin-top: 1rem;
    }
    .box-content, .block-content {
      address {
        min-height: 11.2rem;
      }
      padding: 0.5rem 0.1rem 0 0.1rem;
    }
    .block-content {
      ol {
        display: inline-flex;
        flex-wrap: wrap;
        padding-left: 1rem;
      }
    }
    .box-actions {
      text-align: right;
    }
    .block-addresses-list {
      .actions {
        text-align: left;
        margin-bottom: 0.8rem;
        .btn {
          margin-right: 0.5rem;
          margin-bottom: 0.1rem;
        }
      }
      &.block-content {
        display: inline-flex;
        flex-wrap: wrap;
      }
    }
    .actions-toolbar {
      width: 100%;
      text-align: right;
      .actions {
        padding-top: 1rem;
        border-top: 1px solid #ccc;
      }
      button {
        margin: 0 0 0 1rem;
      }
      a:before {
        content: '< ';
      }
    }
  }
}

.customer-address-form {
  .box-content {
    select {
      &#country {
        @extend .form-control
      }
    }
  }
  .actions-toolbar {
    width: 100%;
    text-align: right;
    .actions {
      padding-top: 1rem;
      border-top: 1px solid #ccc;
    }
    button {
      margin: 0 0 0 1rem;
    }
    a:before {
      content: '< ';
    }
  }
}

.customer-account-edit {
  .row {
    &.row-dashboard {
      width: 100%;
      .box {
        margin: 1rem 0 0 0;
      }
    }
  }
  .actions-toolbar {
    width: 100%;
    display: inline;
    text-align: right;
    border-top: 1px solid #ccc;
    padding-top: 1rem;
    margin: 0 0.5rem;
    button {
      margin: 0 0 0 1rem;
    }
    a:before {
      content: '< ';
    }
  }
}

.sales-order-history {
  .orders-history {
    margin-top: 1rem;
    .table-order-items {
      .actions {
        text-align: right;
      }
      td {
        &.actions {
          a {
            margin-left: 1rem;
          }
          a:after {
            content: '>';
          }
        }
      }
    }
  }
}

.sales-order-view {
  .account-nav-content {
    display: none;
  }
  .order-links {
    display: none;
  }
  .page-title-wrapper {
    .actions {
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: right;
      a {
        margin-left: 1rem;
        @extend .btn;
        @extend .btn-primary;
      }
    }
  }
  .block-order-details-view {
    .table {
      caption {
        display: none;
      }
      th, td {
        padding-left: 0
      }
    }
    .block-title {
      @extend .headline;
      strong {
        font-weight: normal;
      }
    }
    .box {
      padding: 0.5rem;
    }
    .box-title {
      text-transform: uppercase;
      margin-bottom: 0.3rem;
      display: block;
    }
  }
  .order-details-items {
    .actions-toolbar {
      margin-bottom: 1rem;
      text-align: right;
      a {
        margin-left: 1rem;
        @extend .btn;
        @extend .btn-default;
      }
    }
    .order-title {
      @extend .headline;
      strong {
        font-weight: normal;
      }
    }
    .table-order-items {
      margin-bottom: 0;
      caption {
        display: none;
      }
      tbody {
        td {
          &.qty {
            ul {
              list-style: none;
              padding: 0;
              margin-bottom: 0;
            }
          }
        }
        strong {
          font-weight: normal;
        }
        td {
          &.subtotal {
            text-align: right;
          }
        }
      }
      tfoot {
        th {
          background: none;
        }
        text-align: right;
        font-size: 1.2rem;
        tr {
          &.subtotal, &.shipping {
            th {
              font-weight: normal;
              &:after {
                content: ':';
                margin-left: -3px;
              }
            }
          }
          &.grand_total {
            font-weight: bold;
            th {
              &:after {
                content: ':';
                margin-left: -3px;
              }
            }
          }
        }
      }
    }
  }
}

.shipping-tracking-popup {
  .actions {
    button {
      @extend .btn-primary;
      text-shadow: none;
      opacity: 1;
    }
  }
  .page {
    .subtitle {
      @extend .headline;
      strong {
        font-weight: normal;
      }
    }
    margin: 1rem;
  }
  .col {
    width: 50%;
  }
  .table-caption {
    caption-side: top;
    padding-left: 0.75rem;
    color: $text-color;
    text-transform: uppercase;
  }
}
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/functions";

.header-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;

  .no-space {
    margin-bottom: 0;
  }

  /* Bases */
  .box-base {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 33.33333%;

    .content {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  .box-3col-base {
    @extend .col-sm-12;
    @extend .col-md-6;
    @extend .col-lg-4;
    @extend .box-base;
  }

  .box-2col-base {
    @extend .col-12;
    @extend .box-base;
  }

  .box-img-base {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .box-text-base {
    padding-bottom: 0 !important;

    .content-text {
      @extend .content-text-base;
    }
  }

  .content-text-base {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin: 0;
    padding: 1rem;
    height: 100%;
    color: #ffffff;
    background-color: #54585a;

    .content-title {
      margin-top: -17px;
      font-size: 3.5rem;
      line-height: 1.4;
      text-transform: unset;
    }

    .content-description {
      margin: 0;
    }
  }

  .box-2col-slider {
    padding-bottom: 0 !important;

    .slider-images {
      display: none;
    }

    .slider-images.slick-initialized {
      display: block;
    }

    .slider-image-wrapper {
      position: relative;

      .slider-image {
        position: relative;
        padding-bottom: 50%;
      }

      .product-overlay {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover .plus-toggle {
          transform: scale(1.4);
        }
      }

      .plus-toggle {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 20%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        transition: transform 0.25s;
      }

      .plus-toggle::before {
        content: "+";
        margin-top: -3px;
      }

      /* Tooltip */
      .product-tooltip {
        display: none;
        position: absolute;
        top: 57%;
        background-color: #ffffff;
        border-radius: 5px;
        max-width: 12ch;
      }

      .tip {
        position: relative;
      }

      .link-text {
        display: block;
        white-space: nowrap;
        font-size: 0.7rem;
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding: 0.25rem;
      }

      .link-text::before {
        content: "\203A";
        color: #54585a;
        float: right;
        margin-left: .2em;
      }

      .product-tooltip::before {
        content: "";
        position: absolute;
        top: -35%;
        left: 50%;
        transform: translate(-50%);
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ffffff;
        z-index: 999;
      }
    }

    .slick-dots {
      bottom: 0.65rem;

      & li button::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: #54585a;
        opacity: 0.10;
        border-radius: 50%;
      }

      & li.slick-active button::before {
        opacity: 0.75;
      }
    }
  }

  /* Layout classes */
  .box-3col,
  .box-3col-text,
  .box-3col-img {
    @extend .box-3col-base;
  }

  .box-2col,
  .box-2col-text,
  .box-2col-img,
  .box-2col-slider {
    @extend .box-2col-base;
  }

  .box-3col-text,
  .box-2col-text {
    @extend .box-text-base;
  }

  .box-3col-img,
  .box-2col-img {
    @extend .box-img-base;
  }

  .box-2col-base:nth-child(1) {
    @extend .col-lg-4;
  }

  .box-2col-base:nth-child(2) {
    @extend .col-lg-8;
  }

  /* Other */

  .box-discover {
    @extend .col-12;
    margin: 3.5rem 0;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: inherit;
  }

  .box-discover:before,
  .box-discover:after {
    content: "\2193";
    font-family: MetaPro-Normal, serif;
    font-weight: 100;
    margin: 0 .5rem;
    color: #5a5e62;
  }

  /* Media queries */
  @include media-breakpoint-down(md) {

    .box-base {
      padding-bottom: 50%;
    }

    .box-3col-base:last-child {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {

    .box-base {
      padding-bottom: 50%;
    }
  }
}

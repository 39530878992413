@import '../components/products-display';
@import '../base/helpers';
@import '../components/variables';

.search-index-index {
  .products {
    @include products-display();
  }

  .advanced-search {

    .custom-select:focus {
      box-shadow: unset;
    }

    @include create-flex-complete($wrap: wrap, $justify: center);
    margin-bottom: 1rem;

    .multiselect-native-select {
      @extend .col-12;
      @extend .col-md-6;
      @extend .col-lg-3;
      @extend .mb-3;
    }

    // Multiselect styles
    .multiselect-container {
      width: 100%;

      .multiselect-option {
        text-align: center;

        .form-check {
          .form-check-input {
            right: 0;
            z-index: -1;
            opacity: 0;

            &:checked ~ .custom-filter-checkbox {
              background: #000000;
            }

          }

          .custom-filter-checkbox {
            position: absolute;
            top: 8px;
            right: 8px;
            height: 6px;
            width: 6px;
            background: #ffffff;
            border: 1px solid #C7C9C7;
            border-radius: 20px;

          }
        }
      }
    }

    .multiselect {
      text-align: center;
    }

    .form-control {
      border-width: 1px;
    }

    .input-group-text {
      background-color: #FFFFFF;
    }

    /* Slider, maybe a component ? */
    .ui-slider-handle {
      height: 14px !important;
      width: 14px !important;
      background-color: #54585a;
      top: -2px;
    }
  }

  @include create-dropdown-container(1.25rem, #ffffff, 0);
}

/* Styles for MageWorx_GiftCards extension */


/* Checkout page */
#giftcard-info dt {
  margin: 0 11px 0 0 !important;
}


/* Product page */

.step-label h3 span {
  line-height: 25px !important;
}

#other_amount,
#card-preview {
  margin-bottom: 1rem !important;
}

#card-preview {
  display: block !important;
}

.stock {
  text-align: left !important;
}

#mail-message {
  width: 100% !important;
}

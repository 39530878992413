@import "base/variables";

$enable-rounded: false;
$grid-gutter-width: 14px;
$font-family-base: $font-family_meta-light;

$btn-primary-color: #EDEEEF;
$btn-primary-bg: #383737;
$input-border-color: #969595;
$input-color-placeholder: #969595;

$gray-100: #f8f9fa;
$gray-600: #b7b8bb;
$gray-800: #343a40;
$black: #000;

$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;

$primary-brown: #383737;

$theme-colors: (
        primary: $primary-brown,
        secondary: $gray-600,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800
);


$font-size-h1: 2.0rem;
$font-size-h2: 1.5rem;
$font-size-h3: 1.17rem;

$error-color: $red;